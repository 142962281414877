import { Vue, Options } from 'vue-class-component';
import fruttaMeClient from '@/core/api/fruttame-service';
import {
    IResult,
    SupplierDashboardTransportDTO,
    TransportGridDTO,
} from '@/../client-generator/generated-clients/client';
import { SET_APP_LOADING } from '@/core/store/action-types';
import { DEFAULT_WEIGHT, getColtureName } from '@/core/utilities/utils';
import { PAGE_SALES_ARCHIVE, PAGE_SALES_PLANNING } from '@/core/router/pages';
import { checkmarkOutline } from 'ionicons/icons';
import ReportAProblem from '@/views/components/ReportAProblem/ReportAProblem.vue';
import { modalController } from '@ionic/vue';

@Options({
    name: 'TransportDetail',
    components: {
        ReportAProblem,
    },
})
export default class TransportDetail extends Vue {
    transportId = -1;
    transport: TransportGridDTO | null = null;
    transportProduct: SupplierDashboardTransportDTO | null = null;
    defaultWeight = DEFAULT_WEIGHT;
    getColtureName = getColtureName;
    checkmarkOutline = checkmarkOutline;
    ReportAProblem = ReportAProblem;
    panelSendIssue: HTMLIonModalElement | null = null;

    get isCompleted() {
        return this.transport?.stateId === 2;
    }

    async created() {
        this.transportId = +this.$route.params.transportId;
        await this.fetchTransportDetail();
        await this.fetchTransportProductDetail();
    }

    async fetchTransportDetail() {
        this.$store.dispatch(SET_APP_LOADING, true);
        try {
            this.transport = (
                await fruttaMeClient.transportGet(this.transportId)
            ).data as TransportGridDTO;
        } catch (error) {
            console.error(error);
            this.$messageService.error(
                (error.data as IResult)?.errorMessage ??
                    this.$t('general.error')
            );
        } finally {
            this.$store.dispatch(SET_APP_LOADING, false);
        }
    }

    async fetchTransportProductDetail() {
        this.$store.dispatch(SET_APP_LOADING, true);
        try {
            this.transportProduct = (
                await fruttaMeClient.transportProduct(this.transportId, 'it')
            ).data as SupplierDashboardTransportDTO;
        } catch (error) {
            console.error(error);
            this.$messageService.error(
                (error.data as IResult)?.errorMessage ??
                    this.$t('general.error')
            );
        } finally {
            this.$store.dispatch(SET_APP_LOADING, false);
        }
    }

    goBackToList() {
        this.$router.push({
            name: this.isCompleted ? PAGE_SALES_ARCHIVE : PAGE_SALES_PLANNING,
        });
    }

    async openSendIssuePanel() {
        this.panelSendIssue = await modalController.create({
            component: ReportAProblem,
            backdropDismiss: true,
            cssClass: 'panel shortest',
            componentProps: {
                saleDetail: this.transportProduct,
                closePanel: this.closePanel,
            },
        });

        this.panelSendIssue.present();
    }

    closePanel() {
        if (this.panelSendIssue) {
            this.panelSendIssue.dismiss();
        }
    }
}
