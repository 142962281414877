import { Vue, Options } from 'vue-class-component';
import {
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonText,
    IonTextarea,
} from '@ionic/vue';
import {
    CommentMessageDTO,
    IResult,
    SupplierDashboardTransportDTO,
} from '@/../client-generator/generated-clients/client';
import { SET_APP_LOADING } from '@/core/store/action-types';
import fruttaMeClient from '@/core/api/fruttame-service';
import { PAGE_SALES_PLANNING } from '@/core/router/pages';

@Options({
    name: 'ReportAProblem',
    components: {
        IonIcon,
        IonItem,
        IonLabel,
        IonList,
        IonText,
        IonTextarea,
    },
    props: {
        saleDetail: {
            type: SupplierDashboardTransportDTO,
            required: false,
        },
        closePanel: {
            type: Function,
            required: false,
        },
    },
})
export default class ReportAProblem extends Vue {
    saleDetail!: SupplierDashboardTransportDTO | null;
    closePanel!: Function;
    reportText = '';
    wasSent = false;
    doAnnulla() {
        this.closePanel();
    }
    gotoPlanningList() {
        this.closePanel();
        this.$router.push({ name: PAGE_SALES_PLANNING });
    }
    async doInvia() {
        if (
            !this.reportText ||
            !this.saleDetail ||
            !this.saleDetail.transportId ||
            this.wasSent
        )
            return;

        await this.sentTrasportNotify(
            this.saleDetail.transportId,
            this.reportText
        );
        this.wasSent = true;
    }
    async sentTrasportNotify(transportId: number, message: string) {
        if (!transportId || !message) return;

        this.$store.dispatch(SET_APP_LOADING, true);
        try {
            await fruttaMeClient.notifyproblem(transportId, {
                message: message,
            } as CommentMessageDTO);
        } catch (error) {
            console.error(error);
            this.$messageService.error(
                (error.data as IResult).errorMessage ?? this.$t('general.error')
            );
        } finally {
            this.$store.dispatch(SET_APP_LOADING, false);
        }
    }
}
