<template>
    <div v-if="!wasSent" class="box-report-problem">
        <div class="p-3">
            <h1 class="fs-18">
                <span
                    class="cursor-pointer float-right fs-20 pr-3 d-inlineblock"
                    @click="doAnnulla()"
                    >&times;</span
                >
                {{ saleDetail.transportCode }}&nbsp;
                <span
                    class="badge dark-grey fs-12"
                    v-if="saleDetail.state === 1"
                    >{{ $t("reportAProblem.organizedBadgeText") }}</span
                >
            </h1>

            <h3 class="fs-17">{{ $t("reportAProblem.title") }}</h3>
            <div class="mb-3 fs-15">
                {{ $t("reportAProblem.description") }}
            </div>
            <div class="mb-3">
                <ion-textarea
                    :placeholder="$t('reportAProblem.textareaPlaceholder')"
                    class="border fs-14 custom-text-area"
                    v-model="reportText"
                />
            </div>
        </div>
        <div class="d-flex w-100 p-3 position-absolute bottom-0">
            <ion-button
                color="dark"
                fill="outline"
                class="w-100"
                @click="doAnnulla()"
            >
                {{ $t("general.cancel") }}
            </ion-button>
            <ion-button
                color="primary"
                class="w-100"
                :disabled="!reportText"
                @click="doInvia()"
            >
                {{ $t("general.send") }}
            </ion-button>
        </div>
    </div>

    <div v-if="wasSent" class="text-center p-3">
        <div class="fs-20">
            <span
                class="cursor-pointer float-right pr-3 d-inlineblock"
                @click="doAnnulla()"
                >&times;</span
            >&nbsp;
        </div>
        <font-awesome-icon
            :icon="['fal', 'check-circle']"
            class="fs-50 text-warning"
        />
        <h3 class="fs-18">{{ $t("reportAProblem.issueSent") }}</h3>
        <div class="text-center mb-3">
            {{ $t("reportAProblem.issueSentText") }}
        </div>
    </div>
    <div v-if="wasSent" class="position-absolute bottom-0 w-100 p-3">
        <ion-button
            color="dark"
            fill="outline"
            class="w-100"
            @click="gotoPlanningList()"
        >
            {{ $t("general.close") }}
        </ion-button>
    </div>
</template>

<script lang="ts" src="./ReportAProblem.ts"></script>

<style lang="scss" src="./ReportAProblem.scss"></style>
