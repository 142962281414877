<template>
    <ion-page>
        <TheHeader :goBack="goBackToList" />
        <ion-content>
            <div class="page-transport-detail px-4 fs-14" v-if="transport">
                <h1 class="fs-20 mb-4 mt-0">
                    TRS-{{ transport.transportId }}
                    <span
                        class="badge dark-grey"
                        v-if="transport.stateId === 1"
                    >
                        {{ $t("transportDetail.organized") }}
                    </span>
                </h1>
                <ion-card class="m-0">
                    <ion-card-header>
                        <ion-card-title v-if="transportProduct">
                            <div class="d-flex align-items-start text-dark">
                                <div>
                                    <img
                                        :src="transportProduct.productIconUrl"
                                        :alt="transportProduct.productName"
                                    />
                                </div>
                                <div class="w-100 pl-2">
                                    <h1 class="fs-18 my-0">
                                        {{ transportProduct.productName }}
                                    </h1>
                                    <span
                                        class="
                                            badge
                                            text-lowercase
                                            mr-1
                                            text-white
                                            default-orange
                                        "
                                        :style="
                                            'background-color:' +
                                            transportProduct.manufactureColor +
                                            ';'
                                        "
                                    >
                                        {{
                                            transportProduct.productManufacture
                                        }}
                                    </span>
                                    <span class="badge badge-outline">
                                        {{ transportProduct.productVariety }}
                                    </span>
                                </div>
                                <div class="pl-3 pt-2 text-right">
                                    <div
                                        class="
                                            text-nowrap
                                            fs-12
                                            fw-normal
                                            quantity-badge
                                            d-flex
                                        "
                                        v-if="isCompleted"
                                    >
                                        <span class="mr-1"
                                            >{{ transport.quantity }}Kg</span
                                        >
                                        <ion-icon
                                            :ios="checkmarkOutline"
                                            :md="checkmarkOutline"
                                        ></ion-icon>
                                    </div>
                                    <div
                                        class="text-nowrap fs-12 fw-normal"
                                        v-else
                                    >
                                        {{ defaultWeight }}Kg
                                    </div>
                                    <div class="text-nowrap fs-14 mt-2">
                                        {{ transportProduct.price }}€/Kg
                                    </div>
                                </div>
                            </div>
                            <div class="colture-txt fs-14 text-nowrap mt-3">
                                {{ getColtureName(transportProduct.colture) }},
                                {{ transportProduct.transport }}
                            </div>
                        </ion-card-title>
                    </ion-card-header>
                </ion-card>
                <div class="mt-4">
                    <div class="d-flex align-items-baseline">
                        <h2 class="fs-18 m-0">
                            {{ $t("transportDetail.departure") }}
                        </h2>
                        <span class="ml-auto"
                            >{{
                                transport.startDate.toLocaleString(undefined, {
                                    weekday: "short",
                                    month: "2-digit",
                                    day: "2-digit",
                                    year: "numeric",
                                })
                            }}
                            -
                            {{
                                transport.startDate.toLocaleTimeString(
                                    undefined,
                                    { hour: "2-digit", minute: "2-digit" }
                                )
                            }}</span
                        >
                    </div>
                    <div class="fs-16 mt-2">
                        {{ transport.start.city }},
                        {{ transport.start.address }}
                        {{ transport.start.number }} <br />
                        {{ transport.start.postalCode }}
                        {{ transport.start.country }}
                    </div>
                    <div class="mt-3 fs-12">
                        <strong>{{ $t("transportDetail.notes") }}:</strong>
                        {{ transport.start.notes }}
                    </div>
                    <hr class="my-4" />
                    <h2 class="fs-18 m-0">
                        {{ $t("transportDetail.carrier") }}
                    </h2>
                    <div class="mt-2 mb-3 fs-16">{{ transport.carrier }}</div>
                    <div class="d-flex">
                        <strong class="mr-4">
                            {{ $t("transportDetail.plate") }}
                        </strong>
                        <div class="fs-12">
                            <div>
                                {{ $t("transportDetail.engine") }}
                                {{ transport.plate }}
                            </div>
                            <div class="mt-2">
                                {{ $t("transportDetail.trailer") }}
                                {{ transport.trailerPlate }}
                            </div>
                        </div>
                    </div>
                    <div class="mt-3 fs-12">
                        <strong>
                            {{ $t("transportDetail.assignmentNotes") }}:
                        </strong>
                        {{ transport.start.assignmentNotes }}
                    </div>
                    <hr class="mt-4 mb-3" />

                    <ion-button
                        v-if="!isCompleted"
                        color="danger"
                        fill="outline"
                        size="small"
                        class="report-btn"
                        @click="openSendIssuePanel()"
                    >
                        {{ $t("transportDetail.reportProblem") }}
                    </ion-button>
                </div>
            </div>
        </ion-content>
        <ion-footer class="ion-no-border page-transport-detail">
            <hr />
            <ion-button
                color="dark"
                fill="outline"
                expand="block"
                size="large"
                class="back-bottom-btn mx-4"
                @click="goBackToList"
            >
                {{ $t("general.back") }}
            </ion-button>
        </ion-footer>
    </ion-page>
</template>

<script lang="ts" src="./TransportDetail.ts"></script>
<style lang="scss" src="./TransportDetail.scss"></style>
